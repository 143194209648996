import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const HeroWrapper = styled(ShowOn)`
  padding: 56px 0 64px;
`;

const Image = styled.div`
  max-width: 400px;
  margin: auto;
`;

const Text = styled.div`
  padding: 0 20px;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Text>
      <Heading variant={4} marginBottom={16}>
        {heading}
      </Heading>
      <Body variant={4}>{body}</Body>
    </Text>
    <Image>
      <StaticImage
        src="../images/ebook-hero.webp"
        alt="How to Recession Proof Your Retirement eBook"
      />
    </Image>
    <div className="klaviyo-form-RKAQmi"></div>
  </HeroWrapper>
);
