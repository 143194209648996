import { JavascriptLoader } from 'contexts/JavascriptLoaderContext';
import React, { useContext, useEffect } from 'react';

import { HeroLarge } from './HeroLarge';
import { HeroSmall } from './HeroSmall';

const KLAVIYO_SCRIPTS = [
  'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SWXMZd',
];

export const Hero = () => {
  const { scriptsAreLoaded, loadScripts } = useContext(JavascriptLoader);

  useEffect(() => {
    loadScripts(KLAVIYO_SCRIPTS);
  }, []);

  if (!scriptsAreLoaded(KLAVIYO_SCRIPTS)) return null;

  return (
    <>
      <HeroSmall />
      <HeroLarge />
    </>
  );
};
