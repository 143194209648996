import React from 'react';

export const Line = ({ ...props }) => (
  <svg
    width="1064"
    height="4356"
    viewBox="0 0 1064 4356"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M998.273 1C1362.27 969 54.6376 147.361 3.2725 956.5C-43.2275 1689 1104.46 930.5 826.5 1810.5C605.556 2510 -115.728 1832.5 43.7725 2657C157.716 3246 936.609 2585.36 957.773 3220C997.273 4404.5 161.273 3561.5 334.773 4355"
      stroke="#26313B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="16 16"
    />
  </svg>
);
