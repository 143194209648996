import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PanelLargeProps {
  image: React.ReactNode;
  imageAlignment: 'left' | 'right';
  body: string;
  heading: string;
  spacing?: number;
}

const PanelLargeWrapper = styled(Flex)<
  Pick<PanelLargeProps, 'spacing' | 'imageAlignment'>
>`
  max-width: 1488px;
  margin: ${({ spacing = 0 }) => `0 auto ${spacing}px`};
  padding: 0 40px;
  flex-direction: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? 'row' : 'row-reverse'};
`;

const Image = styled.div<Pick<PanelLargeProps, 'imageAlignment'>>`
  flex-basis: 50%;
  padding-right: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? '20px' : '0'};
  padding-left: ${({ imageAlignment }) =>
    imageAlignment === 'left' ? '0' : '20px'};
`;

const Text = styled(Flex)<Pick<PanelLargeProps, 'imageAlignment'>>`
  flex-basis: 50%;
  padding-left: ${({ imageAlignment }) =>
    imageAlignment === 'right' ? '40px' : '60px'};
  align-items: center;
`;

const TextWrapper = styled.div`
  position: relative;
  background: ${colors.greenLight};
  max-width: 597px;
`;

export const PanelLarge: FC<PanelLargeProps> = ({
  imageAlignment,
  image,
  body,
  heading,
  spacing,
}) => (
  <ShowOn screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <PanelLargeWrapper spacing={spacing} imageAlignment={imageAlignment}>
      <Image imageAlignment={imageAlignment}>{image}</Image>
      <Text imageAlignment={imageAlignment}>
        <TextWrapper>
          <ResponsiveHeading variant={2} marginBottom={32}>
            {heading}
          </ResponsiveHeading>
          <ResponsiveBody variant={1}>{body}</ResponsiveBody>
        </TextWrapper>
      </Text>
    </PanelLargeWrapper>
  </ShowOn>
);
