import React from 'react';

import { SpendRetirementWithMoreLarge } from './SpendRetirementWithMoreLarge';
import { SpendRetirementWithMoreSmall } from './SpendRetirementWithMoreSmall';

export const SpendRetirementWithMore = () => (
  <>
    <SpendRetirementWithMoreSmall />
    <SpendRetirementWithMoreLarge />
  </>
);
