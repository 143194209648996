import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import {
  IdealFuture,
  QualityOfLife,
  RecessionImpactRetirement,
  SpendRetirementWithMore,
} from 'page-components/recession-proof-your-retirement';
import { Hero } from 'page-components/recession-proof-your-retirement/hero';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React from 'react';

const RecessionProofYourRetirement = () => (
  <Layout>
    <Navigation />
    <PageWrapper background={colors.greenLight}>
      <Hero />
      <RecessionImpactRetirement />
      <QualityOfLife />
      <IdealFuture />
      <SpendRetirementWithMore />
      <BottomCta
        heading="The tools you need to live your best retirement life."
        body="Download our free guide to safeguard your retirement from whatever the economy might throw your way."
        buttonText="Download Now"
        onClick={() => window.scrollTo(0, 0)}
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default RecessionProofYourRetirement;

export const Head = () => (
  <SEO
    title="Recession Proof Your Retirement"
    description="Learn how to effectively plan, invest and spend during retirement to safeguard against economic uncertainty and a potential recession."
    imagePath="e-book-featured.png"
  />
);
