import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { device } from 'component-library/styles/device';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { Line } from '../images/Line';
import { body, heading } from './content';

const Wrapper = styled(ShowOn)`
  padding: 80px 40px 120px;
  max-width: 1480px;
  margin: auto;
  position: relative;
`;

const Text = styled(Flex)`
  width: 55%;
`;

const Image = styled(Flex)`
  width: 40%;
  z-index: 2;
  margin-left: 40px;
  align-self: end;
`;

const StyledResponsiveBody = styled(ResponsiveBody)`
  max-width: 290px;

  ${`@media ${device.xl}`} {
    max-width: 500px;
  }

  ${`@media ${device.xxl}`} {
    max-width: 614px;
  }
`;

const KlaviyoForm = styled.div`
  z-index: 2;
`;

export const StyledLine = styled(Line)`
  position: absolute;
  top: 20%;
  left: 10%;
  width: 61%;
  display: none;

  ${`@media ${device.xl}`} {
    display: block;
  }
`;

export const HeroLarge = () => (
  <Wrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Flex alignItems="center" justifyContent="space-between">
      <Text column>
        <ResponsiveHeading variant={1} marginBottom={24}>
          {heading}
        </ResponsiveHeading>
        <StyledResponsiveBody variant={1} marginBottom={48}>
          {body}
        </StyledResponsiveBody>
        <KlaviyoForm className="klaviyo-form-RKAQmi"></KlaviyoForm>
      </Text>
      <Image>
        <StaticImage
          src="../images/ebook-hero.webp"
          alt="How to Recession Proof Your Retirement eBook"
        />
      </Image>
    </Flex>
    <StyledLine />
  </Wrapper>
);
