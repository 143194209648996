import React from 'react';

import { IdealFutureLarge } from './IdealFutureLarge';
import { IdealFutureSmall } from './IdealFutureSmall';

export const IdealFuture = () => (
  <>
    <IdealFutureSmall />
    <IdealFutureLarge />
  </>
);
