import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { PanelLarge } from '../shared/PanelLarge';
import { body, heading } from './content';

export const QualityOfLifeLarge = () => (
  <PanelLarge
    image={
      <StaticImage
        src="../images/quality-of-life-large.webp"
        alt="multi-generational-family"
      />
    }
    imageAlignment="right"
    body={body}
    heading={heading}
  />
);
