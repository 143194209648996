import React from 'react';

import { QualityOfLifeLarge } from './QualityOfLifeLarge';
import { QualityOfLifeSmall } from './QualityOfLifeSmall';

export const QualityOfLife = () => (
  <>
    <QualityOfLifeSmall />
    <QualityOfLifeLarge />
  </>
);
