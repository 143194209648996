import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React, { FC } from 'react';
import styled from 'styled-components';

interface PanelSmallProps {
  image: React.ReactNode;
  heading: string;
  body: string;
}

const Image = styled.div`
  max-width: 400px;
  margin: auto;
`;

const Text = styled.div`
  padding: 0 20px 46px;
`;

export const PanelSmall: FC<PanelSmallProps> = ({ image, heading, body }) => (
  <ShowOn screens={[Screen.Sm, Screen.Md]}>
    <Image>{image}</Image>
    <Text>
      <Heading variant={4} marginBottom={16} marginTop={32}>
        {heading}
      </Heading>
      <Body variant={4}>{body}</Body>
    </Text>
  </ShowOn>
);
