import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { PanelSmall } from '../shared/PanelSmall';
import { body, heading } from './content';

export const RecessionImpactRetirementSmall = () => (
  <PanelSmall
    image={
      <StaticImage
        src="../images/recession-impact-retirement-small.webp"
        alt="man-sitting-at-table"
      />
    }
    heading={heading}
    body={body}
  />
);
