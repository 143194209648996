import React from 'react';

import { RecessionImpactRetirementLarge } from './RecessionImpactRetirementLarge';
import { RecessionImpactRetirementSmall } from './RecessionImpactRetirementSmall';

export const RecessionImpactRetirement = () => (
  <>
    <RecessionImpactRetirementSmall />
    <RecessionImpactRetirementLarge />
  </>
);
